import { useTranslation } from "react-i18next";
import "./external_notifications.scss";
import { GreenhouseLogo } from "./greenhouse_logo";
import { COLOR } from "~/types/ui";
import { useEffect, useState } from "react";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";

type Props = {
  urlToken: string;
  companyName: string;
};

const ExternalNotifications = (props: Props) => {
  const { t } = useTranslation("board");
  const { urlToken, companyName } = props;
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");
  const { preview_mode } = useBoardConfiguration();

  useEffect(() => {
    setJobSeekersUrl(
      `${window.ENV.JOB_SEEKERS_URL}/users/sign_in?job_board=${encodeURIComponent(urlToken)}`
    );
  }, [urlToken]);

  return (
    <div className={"external_notifications"}>
      <div className={"external_notifications__header"}>
        <div className={"external_notifications__header__title"}>
          <div>{t("external_notifications.notify_me")}</div>
        </div>
        <div className={"external_notifications__header__description"}>
          {t("external_notifications.subscribe", {
            companyName: companyName,
            interpolation: { escapeValue: false },
          })}
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      {preview_mode ? (
        <button className="external_notifications__button">
          {t("external_notifications.sign_up")}
        </button>
      ) : (
        <a
          className="external_notifications__button"
          target="_blank"
          href={jobSeekersUrl}
          rel="noreferrer"
        >
          {t("external_notifications.sign_up")}
        </a>
      )}
      <div className={"external_notifications__footer"}>
        <GreenhouseLogo height={30} width={100} color={COLOR.darkGreen} />
      </div>
    </div>
  );
};

export default ExternalNotifications;
